import React, { Component } from 'react';
import {Icon, List, Image, Button, Modal, Popup, Grid, Header, ButtonGroup, TextArea, Form, TransitionablePortal} from 'semantic-ui-react';
import './App.css';
import ItemRenderer from './Components/ItemRenderer';


    
    
let {getMenu, getLineItems, getInfo, updateLineItem, updateDelivery} = require("./comm");

export default class App extends Component {

  constructor(props){
    super(props);
    console.log("Hello World", props.location);
    this.state = {
      menu:[],
      currentOrder:{},
      loggedIn:false,
      modalOpen:false,
      showUserDetails:false,
      customerName:"",
      selectedItemNote:"",
      selectedDelivery:"Delivery option not picked",
      selectedItemQty:0,
      selectedItemName:"",
      selectedItemDescription:"",
      selectedItemPrice:"",
      selectedItemType:"",
      selectedDeliveryPrice:0
    }
    this.closeModal = this.closeModal.bind(this);
    this.updateDelivery = this.updateDelivery.bind(this);
  }

  async loadLines(){
    let lines  = await getLineItems();
    let currentOrder = {}
    for (let i = 0; i < lines.data.length; i++) {
      const line = lines.data[i];
      currentOrder[line.name] = line;
      if(line.name == "Default Delivery"){
        this.setState({
          selectedDelivery: line.description,
          selectedDeliveryPrice: line.price
        });
      }
    }
    this.setState({currentOrder});
  }
  async componentDidMount(){
    let info = await getInfo();
    console.log(info, "HUss Huzz")
    let menu = await getMenu();
    await this.loadLines();
    this.setState({
      menu:menu.data,
      weekNo:info.weekNo,
      loggedIn:info.customer!=null,
      customerName:info.customer,
      customerDetail:info.customerDetail
    })
  }
  openModal(item){

    let order = this.state.currentOrder[item.name];
    if(!order)order = {note:"", qty:0}
    console.log("???", order)
    this.setState({
      modalOpen:true,
      selectedItemName:item.name,
      selectedItemDescription:item.description,
      selectedItemPrice:item.price,
      selectedItemType:item.type,
      selectedItemNote:order.note,
      selectedItemQty:order.qty
    });
  }

  async updateDelivery(item){
    await updateDelivery(item);
    await this.loadLines();


  }

  async closeModal(){
    let body = {
      type        : this.state.selectedItemType,
      name        : this.state.selectedItemName,
      description : this.state.selectedItemDescription,
      qty         : this.state.selectedItemQty,
      note        : this.state.selectedItemNote,
    }
    await updateLineItem(body);
    await this.loadLines();
    this.setState({
      modalOpen:false,
      showUserDetails:false,
      selectedItemName:"",
      selectedItemDescription:"",
      selectedItemNote:"",
      selectedItemPrice:"",
      selectedItemType:"",
      selectedItemQty:0
    });
  }
 
 
  render(){
    let border = "solid 0.5px gray";
    let headerHeight = "80px"
    let footerHeight = "80px";
    let i = 0;
    console.log(this.state.menu);
    let menu = this.state.menu.map((menuType, index)=>{
      let menuItems = menuType.items.map((menuItem, index2)=>{
        return (
          <ItemRenderer itemClick={ () => this.openModal(menuItem) } 
            item={menuItem} 
            order={this.state.currentOrder[menuItem.name]} 
            key={i++}/>
        );
      });
      menuItems.unshift(<ItemRenderer item={{type:"type",name:menuType.type}} key={i++}/>)
      return menuItems;
    });

    let total = 0;
    if(this.state.currentOrder)
    Object.keys(this.state.currentOrder).forEach(lineName => {
      let line = this.state.currentOrder[lineName];
      total += line.qty * line.price;
    })

    if(!this.state.customerName || this.state.customerName  == ""){
      return (
        <div>
          Please contact Diana at 0403 375 857 to get a login link.
        </div>
      )
    }else

    return (


      <div style={{flex:1, resize:"both", width:"100%", overflow:"none"}}>
        <Modal open={this.state.showUserDetails}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={this.closeModal}>
          <Modal.Header>{this.state.customerName}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Address Info:</Header>
              <Modal.Description>{this.state.customerDetail.address}</Modal.Description>
              <Modal.Description>{this.state.customerDetail.suburb} {this.state.customerDetail.postcode} </Modal.Description>
            </Modal.Description>
            <Modal.Description>
              <Header>Contact Info:</Header>
              <Modal.Description>Mobile: {this.state.customerDetail.contact}</Modal.Description>
              <Modal.Description>Email: {this.state.customerDetail.email}</Modal.Description>
            </Modal.Description>
            <Modal.Description>
              <Header>Delivery Staff:</Header>
              <Modal.Description>{this.state.customerDetail.staff}</Modal.Description>
            </Modal.Description>
          
          </Modal.Content>

        </Modal>


        <Modal open={this.state.modalOpen}
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  onClose={this.closeModal}>
          <Modal.Header>{this.state.selectedItemType}</Modal.Header>
          <Modal.Content image>
              <Image centered={true} rounded={true} size='small' src={"/assets/"+this.state.selectedItemName+".jpg"} />
          </Modal.Content>
          <Modal.Content>
            <Modal.Description>
            <Header>{this.state.selectedItemName} - ${this.state.selectedItemPrice} ea</Header>
              {
                this.state.selectedItemDescription.split("\n").map((item, i) => {
                    return <Modal.Description key={i}>{item}</Modal.Description>;
                })
              }         
            </Modal.Description>
          
          </Modal.Content>
          <Modal.Content>
              <ButtonGroup fluid>
                <Button color="grey" onClick={()=>{
                  let selectedItemQty = this.state.selectedItemQty;
                  if(selectedItemQty > 0) selectedItemQty--;
                  this.setState({selectedItemQty});
                }}>
                  <Icon name='minus' />
                </Button>
                <Button  color="grey" style={{fontSize:18}} basic>{this.state.selectedItemQty}</Button>
                <Button color="grey" onClick={()=>{
                  let selectedItemQty = this.state.selectedItemQty;
                  if(selectedItemQty < 999) selectedItemQty++;
                  this.setState({selectedItemQty});
                }}>
                  <Icon name='plus'/>
                </Button>
              </ButtonGroup>
          </Modal.Content>
          <Modal.Content>
              <Header size="tiny">Note:</Header>
              <Form>
                <TextArea value={this.state.selectedItemNote} onChange={(event)=>{
                  this.setState({
                    selectedItemNote:event.target.value
                  })
                }}/>
              </Form>
            {/* {
              this.state.selectedItemQty>0 &&
              <Modal.Description >
                <Header floated="right" >Total: ${ (this.state.selectedItemQty * this.state.selectedItemPrice).toFixed(2)}</Header>
            
              </Modal.Description>
            } */}
          </Modal.Content>
          <Modal.Content >
            <Button fluid color="green" size="medium" basic onClick={this.closeModal}> <Icon name='checkmark'/>DONE</Button>
          </Modal.Content>
        </Modal>

        <div style={{border , position:"absolute", top:0, height:headerHeight, width:"100%"}}>
          <div style={{border,float:"right", height:headerHeight,width:"70%", textAlign:"right", paddingRight:20}}>
            <h2 style={{marginLeft:"10px", marginTop:"10px", marginBottom:"-20px"}}>Diana's Kitchen</h2>
          <h4 style={{marginLeft:"10px", marginBottom:"-10px"}}>Week {this.state.weekNo}</h4>
          </div>
  
          <div style={{border,float:"left", height:headerHeight,width:"30%"}}>
            <div onClick={()=>{
              this.setState({showUserDetails:true})
            }} className="vertical-center"  style={{width:"30%", textAlign:"center"}}>
              <Icon  name='user' size="large"/>
              <p>{this.state.customerName}</p>
            </div>
          </div>
        </div>
  
        <div style={{overflow:"auto", border, position:"absolute", top:headerHeight, bottom:footerHeight, width:"100%"}}>
         
            <List celled>
              {menu}
            </List>
        </div>
        <div style={{border, position:"absolute", bottom:0, height:footerHeight, width:"100%"}}>
          <div style={{border,float:"right", height:footerHeight,width:"70%", textAlign:"right", paddingRight:5, paddingTop:5}}>
            <h2 style={{marginLeft:"0px", marginRight:"0px", marginTop:"0px", marginBottom:"-10px"}}>Total: ${total.toFixed(2)}</h2>
            <h5 style={{marginLeft:"0px", marginRight:"0px", marginTop:"10px", marginBottom:"-10px"}}>This order auto submits Tuesday night, you can make changes before then.</h5>
          </div>
          <div style={{border,float:"left", height:footerHeight,width:"30%"}}>
            <div className="vertical-center"  style={{width:"30%", textAlign:"center"}}>
              
  
              <Popup style={{width:"98%", textAlign:"center"}} basic trigger={
                (
                  <div>
                    <Icon  name='shipping fast' size="large"/>
                    <h5 style={{marginLeft:"0px", marginTop:"0px", marginBottom:"-10px"}}>{this.state.selectedDelivery}</h5>
                  </div>
                  
                )} flowing >
                <Grid centered divided columns={2}>
                  <Grid.Column textAlign='center'>
                    <Header as='h4'>Pickup <br/>@ Macleod</Header>
                    <Button color="green" onClick={()=>{this.updateDelivery("macleod")}}>FREE</Button>
                  </Grid.Column>
	          {/*<Grid.Column textAlign='center'>
                    <Header as='h4'>Pickup <br/>@ Laguna</Header>
                    <Button color="green" onClick={()=>{this.updateDelivery("laguna")}}>FREE</Button>
                  </Grid.Column>*/}
                  <Grid.Column textAlign='center'>
                    <Header as='h4'>Home<br/>Delivery</Header>
          <Button color="green"onClick={()=>{this.updateDelivery("delivery")}}> ${Number(this.state.customerDetail.defaultDelivery).toFixed(2)}</Button>
                  </Grid.Column>
                </Grid>
              </Popup>
              
            </div>
          </div>
        </div>
      </div>
    );
  }  
}
