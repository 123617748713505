const axios = require('axios');

module.exports =  {
    
    getMenu:async () => {
        return await axios.get('/api/menu');
    },
    getInfo:async ()=>{
        let weekNo = await axios.get('api/info');
        return weekNo.data;
    },
    getLineItems:async () => {
        return await axios.get('/api/lineItem');
    },
    updateLineItem:async(body)=>{
        return await axios.post('api/lineItem',body);
    },
    submit:async()=>{
        return await axios.post('api/submit');
    },
    updateDelivery:async(item)=>{
        return await axios.post('api/updateDelivery',{item});
    }
}