import React, { Component } from "react";
import { List, Image, Button, Label } from "semantic-ui-react";


export default class ItemRenderer extends Component{
  
  constructor(props){
    super(props);
    console.log(props);
  }
  render(){
    if(this.props.item.name == "FREE") return <div/>
    if(this.props.item.type === "type"){
      return(
        <List.Item style={{backgroundColor:"#EEE"}}>
          <List.Content>
            <h3>{this.props.item.name}</h3>
          </List.Content>
        </List.Item>
      )
    }else{
      return (
        <List.Item onClick={this.props.itemClick}>
          
          {
              this.props.order && this.props.order.qty>0 &&
              <List.Content floated="right" >
                <div style={{marginTop:"10px"}}>
                    <h3>{(this.props.order.qty)}x = [${(this.props.order.qty * this.props.order.price).toFixed(2)}]</h3>
                    
                </div>      
              </List.Content>
          }
          
          <Image avatar src={"/assets/"+this.props.item.name+".jpg"}/>    
          <List.Content>
            <List.Header>
                <h3>{this.props.item.name}</h3>
                ${this.props.item.price}
            </List.Header>
            {
                this.props.item.description.split("\n").map((item, i) => {
                    if(i<3)return <List.Description key={i}>{item}</List.Description>;
                    if(i==4)return <List.Description key={i}>... tap to see more</List.Description>;
                })
            }         
            
          </List.Content>
          
        </List.Item>
      );  
    }
  }
}